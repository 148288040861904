import React from 'react';
import { graphql } from 'gatsby';
import { camelizeKeys } from 'humps';
import { shape } from 'prop-types';
import { isEmpty } from 'lodash';

import Layout from '../../components/Layout';
import DynamicZone from '../../components/DynamicZone';
import Seo from '../../components/seo';

const DynamicPage = ({ data }) => {
  const { dynamicPage } = camelizeKeys(data.strapi);
  const headerData = data.strapi && data.strapi.header;
  const footerData = data.strapi && data.strapi.footer;
  const signUpData = data.strapi && data.strapi.signUp;
  const seo = dynamicPage && dynamicPage.seo;

  return (
    <Layout
      containerClassName="dynamic-page-layout"
      headerData={headerData}
      footerData={footerData}
      signUpData={signUpData}
    >
      {seo && (
        <Seo
          title={seo?.title}
          description={seo?.description}
        />
      )}
      {!isEmpty(dynamicPage) && (
        <DynamicZone
          components={dynamicPage.content}
        />
      )}
    </Layout>
  );
};

DynamicPage.propTypes = {
  data: shape({
    strapi: shape(),
  }),
};

DynamicPage.defaultProps = {
  data: {
    strapi: {},
  },
};

export default DynamicPage;

export const dynamicPageQuery = graphql`
  query getDynamicPage($id: ID!) {
    strapi {
      signUp {
        title
        label
        buttonLabel
        backgroundImage {
          url
        }
      }
      footer {
        id
        links {
          id
          label
          path
          openInNewWindow
          offerIdEverflow
          data {
            path
            label
            openInNewWindow
          }
        }
        socialLinksTitle
        logo {
          id
          url
        }
        socialLinks {
          label
          id
          socialLink {
            path
            label
            logo {
              id
              url
            }
            hoverImage {
              id
              url
            }
          }
        }
        linksTitle
      }
      header {
        id
        links {
          id
          isRed
          linkData {
            id
            label
            path
            openInNewWindow
            offerIdEverflow
            data {
              id
              label
              path
              openInNewWindow
            }
          }
        }
        socialLinks {
          id
          label
          socialLink {
            path
            label
            logo {
              url
            }
          }
        }
        logo {
          url
        }
      }
      dynamicPage(id:$id) {
        seo {
          description
          id
          title
        }
        content {
          ... on STRAPI_ComponentSectionsContentDynamicPage {
            id
            __typename
            backgroundText
            image {
              url
            }
            text
          }
        }
      }
    }
  }
`;
